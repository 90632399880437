// Colormaps from http://peterkovesi.com/projects/colourmaps/CETperceptual_csv_0_1.zip

// aka 'fire'
export const linear_kryw_0_100_c71 = [
    [0, 0, 0],
    [0.027065, 2.143e-05, 0],
    [0.052054, 7.4728e-05, 0],
    [0.071511, 0.00013914, 0],
    [0.08742, 0.0002088, 0],
    [0.10109, 0.00028141, 0],
    [0.11337, 0.000356, 2.4266e-17],
    [0.12439, 0.00043134, 3.3615e-17],
    [0.13463, 0.00050796, 2.1604e-17],
    [0.14411, 0.0005856, 0],
    [0.15292, 0.00070304, 0],
    [0.16073, 0.0013432, 0],
    [0.16871, 0.0014516, 0],
    [0.17657, 0.0012408, 0],
    [0.18364, 0.0015336, 0],
    [0.19052, 0.0017515, 0],
    [0.19751, 0.0015146, 0],
    [0.20401, 0.0015249, 0],
    [0.20994, 0.0019639, 0],
    [0.21605, 0.002031, 0],
    [0.22215, 0.0017559, 0],
    [0.22808, 0.001546, 1.8755e-05],
    [0.23378, 0.0016315, 3.5012e-05],
    [0.23955, 0.0017194, 3.3352e-05],
    [0.24531, 0.0018097, 1.8559e-05],
    [0.25113, 0.0019038, 1.9139e-05],
    [0.25694, 0.0020015, 3.5308e-05],
    [0.26278, 0.0021017, 3.2613e-05],
    [0.26864, 0.0022048, 2.0338e-05],
    [0.27451, 0.0023119, 2.2453e-05],
    [0.28041, 0.0024227, 3.6003e-05],
    [0.28633, 0.0025363, 2.9817e-05],
    [0.29229, 0.0026532, 1.9559e-05],
    [0.29824, 0.0027747, 2.7666e-05],
    [0.30423, 0.0028999, 3.5752e-05],
    [0.31026, 0.0030279, 2.3231e-05],
    [0.31628, 0.0031599, 1.2902e-05],
    [0.32232, 0.0032974, 3.2915e-05],
    [0.32838, 0.0034379, 3.2803e-05],
    [0.33447, 0.0035819, 2.0757e-05],
    [0.34057, 0.003731, 2.3831e-05],
    [0.34668, 0.0038848, 3.502e-05],
    [0.35283, 0.0040418, 2.4468e-05],
    [0.35897, 0.0042032, 1.1444e-05],
    [0.36515, 0.0043708, 3.2793e-05],
    [0.37134, 0.0045418, 3.012e-05],
    [0.37756, 0.0047169, 1.4846e-05],
    [0.38379, 0.0048986, 2.796e-05],
    [0.39003, 0.0050848, 3.2782e-05],
    [0.3963, 0.0052751, 1.9244e-05],
    [0.40258, 0.0054715, 2.2667e-05],
    [0.40888, 0.0056736, 3.3223e-05],
    [0.41519, 0.0058798, 2.159e-05],
    [0.42152, 0.0060922, 1.8214e-05],
    [0.42788, 0.0063116, 3.2525e-05],
    [0.43424, 0.0065353, 2.2247e-05],
    [0.44062, 0.006765, 1.5852e-05],
    [0.44702, 0.0070024, 3.1769e-05],
    [0.45344, 0.0072442, 2.1245e-05],
    [0.45987, 0.0074929, 1.5726e-05],
    [0.46631, 0.0077499, 3.0976e-05],
    [0.47277, 0.0080108, 1.8722e-05],
    [0.47926, 0.0082789, 1.9285e-05],
    [0.48574, 0.0085553, 3.0063e-05],
    [0.49225, 0.0088392, 1.4313e-05],
    [0.49878, 0.0091356, 2.3404e-05],
    [0.50531, 0.0094374, 2.8099e-05],
    [0.51187, 0.0097365, 6.4695e-06],
    [0.51844, 0.010039, 2.5791e-05],
    [0.52501, 0.010354, 2.4393e-05],
    [0.53162, 0.010689, 1.6037e-05],
    [0.53825, 0.011031, 2.7295e-05],
    [0.54489, 0.011393, 1.5848e-05],
    [0.55154, 0.011789, 2.3111e-05],
    [0.55818, 0.012159, 2.5416e-05],
    [0.56485, 0.012508, 1.5064e-05],
    [0.57154, 0.012881, 2.541e-05],
    [0.57823, 0.013283, 1.6166e-05],
    [0.58494, 0.013701, 2.263e-05],
    [0.59166, 0.014122, 2.3316e-05],
    [0.59839, 0.014551, 1.9432e-05],
    [0.60514, 0.014994, 2.4323e-05],
    [0.6119, 0.01545, 1.3929e-05],
    [0.61868, 0.01592, 2.1615e-05],
    [0.62546, 0.016401, 1.5846e-05],
    [0.63226, 0.016897, 2.0838e-05],
    [0.63907, 0.017407, 1.9549e-05],
    [0.64589, 0.017931, 2.0961e-05],
    [0.65273, 0.018471, 2.0737e-05],
    [0.65958, 0.019026, 2.0621e-05],
    [0.66644, 0.019598, 2.0675e-05],
    [0.67332, 0.020187, 2.0301e-05],
    [0.68019, 0.020793, 2.0029e-05],
    [0.68709, 0.021418, 2.0088e-05],
    [0.69399, 0.022062, 1.9102e-05],
    [0.70092, 0.022727, 1.9662e-05],
    [0.70784, 0.023412, 1.7757e-05],
    [0.71478, 0.024121, 1.8236e-05],
    [0.72173, 0.024852, 1.4944e-05],
    [0.7287, 0.025608, 2.0245e-06],
    [0.73567, 0.02639, 1.5013e-07],
    [0.74266, 0.027199, 0],
    [0.74964, 0.028038, 0],
    [0.75665, 0.028906, 0],
    [0.76365, 0.029806, 0],
    [0.77068, 0.030743, 0],
    [0.77771, 0.031711, 0],
    [0.78474, 0.032732, 0],
    [0.79179, 0.033741, 0],
    [0.79886, 0.034936, 0],
    [0.80593, 0.036031, 0],
    [0.81299, 0.03723, 0],
    [0.82007, 0.038493, 0],
    [0.82715, 0.039819, 0],
    [0.83423, 0.041236, 0],
    [0.84131, 0.042647, 0],
    [0.84838, 0.044235, 0],
    [0.85545, 0.045857, 0],
    [0.86252, 0.047645, 0],
    [0.86958, 0.049578, 0],
    [0.87661, 0.051541, 0],
    [0.88365, 0.053735, 0],
    [0.89064, 0.056168, 0],
    [0.89761, 0.058852, 0],
    [0.90451, 0.061777, 0],
    [0.91131, 0.065281, 0],
    [0.91796, 0.069448, 0],
    [0.92445, 0.074684, 0],
    [0.93061, 0.08131, 0],
    [0.93648, 0.088878, 0],
    [0.94205, 0.097336, 0],
    [0.9473, 0.10665, 0],
    [0.9522, 0.1166, 0],
    [0.95674, 0.12716, 0],
    [0.96094, 0.13824, 0],
    [0.96479, 0.14963, 0],
    [0.96829, 0.16128, 0],
    [0.97147, 0.17303, 0],
    [0.97436, 0.18489, 0],
    [0.97698, 0.19672, 0],
    [0.97934, 0.20846, 0],
    [0.98148, 0.22013, 0],
    [0.9834, 0.23167, 0],
    [0.98515, 0.24301, 0],
    [0.98672, 0.25425, 0],
    [0.98815, 0.26525, 0],
    [0.98944, 0.27614, 0],
    [0.99061, 0.28679, 0],
    [0.99167, 0.29731, 0],
    [0.99263, 0.30764, 0],
    [0.9935, 0.31781, 0],
    [0.99428, 0.3278, 0],
    [0.995, 0.33764, 0],
    [0.99564, 0.34735, 0],
    [0.99623, 0.35689, 0],
    [0.99675, 0.3663, 0],
    [0.99722, 0.37556, 0],
    [0.99765, 0.38471, 0],
    [0.99803, 0.39374, 0],
    [0.99836, 0.40265, 0],
    [0.99866, 0.41145, 0],
    [0.99892, 0.42015, 0],
    [0.99915, 0.42874, 0],
    [0.99935, 0.43724, 0],
    [0.99952, 0.44563, 0],
    [0.99966, 0.45395, 0],
    [0.99977, 0.46217, 0],
    [0.99986, 0.47032, 0],
    [0.99993, 0.47838, 0],
    [0.99997, 0.48638, 0],
    [1, 0.4943, 0],
    [1, 0.50214, 0],
    [1, 0.50991, 1.2756e-05],
    [1, 0.51761, 4.5388e-05],
    [1, 0.52523, 9.6977e-05],
    [1, 0.5328, 0.00016858],
    [1, 0.54028, 0.0002582],
    [1, 0.54771, 0.00036528],
    [1, 0.55508, 0.00049276],
    [1, 0.5624, 0.00063955],
    [1, 0.56965, 0.00080443],
    [1, 0.57687, 0.00098902],
    [1, 0.58402, 0.0011943],
    [1, 0.59113, 0.0014189],
    [1, 0.59819, 0.0016626],
    [1, 0.60521, 0.0019281],
    [1, 0.61219, 0.0022145],
    [1, 0.61914, 0.0025213],
    [1, 0.62603, 0.0028496],
    [1, 0.6329, 0.0032006],
    [1, 0.63972, 0.0035741],
    [1, 0.64651, 0.0039701],
    [1, 0.65327, 0.0043898],
    [1, 0.66, 0.0048341],
    [1, 0.66669, 0.005303],
    [1, 0.67336, 0.0057969],
    [1, 0.67999, 0.006317],
    [1, 0.68661, 0.0068648],
    [1, 0.69319, 0.0074406],
    [1, 0.69974, 0.0080433],
    [1, 0.70628, 0.0086756],
    [1, 0.71278, 0.0093486],
    [1, 0.71927, 0.010023],
    [1, 0.72573, 0.010724],
    [1, 0.73217, 0.011565],
    [1, 0.73859, 0.012339],
    [1, 0.74499, 0.01316],
    [1, 0.75137, 0.014042],
    [1, 0.75772, 0.014955],
    [1, 0.76406, 0.015913],
    [1, 0.77039, 0.016915],
    [1, 0.77669, 0.017964],
    [1, 0.78298, 0.019062],
    [1, 0.78925, 0.020212],
    [1, 0.7955, 0.021417],
    [1, 0.80174, 0.02268],
    [1, 0.80797, 0.024005],
    [1, 0.81418, 0.025396],
    [1, 0.82038, 0.026858],
    [1, 0.82656, 0.028394],
    [1, 0.83273, 0.030013],
    [1, 0.83889, 0.031717],
    [1, 0.84503, 0.03348],
    [1, 0.85116, 0.035488],
    [1, 0.85728, 0.037452],
    [1, 0.8634, 0.039592],
    [1, 0.86949, 0.041898],
    [1, 0.87557, 0.044392],
    [1, 0.88165, 0.046958],
    [1, 0.88771, 0.04977],
    [1, 0.89376, 0.052828],
    [1, 0.8998, 0.056209],
    [1, 0.90584, 0.059919],
    [1, 0.91185, 0.063925],
    [1, 0.91783, 0.068579],
    [1, 0.92384, 0.073948],
    [1, 0.92981, 0.080899],
    [1, 0.93576, 0.090648],
    [1, 0.94166, 0.10377],
    [1, 0.94752, 0.12051],
    [1, 0.9533, 0.14149],
    [1, 0.959, 0.1672],
    [1, 0.96456, 0.19823],
    [1, 0.96995, 0.23514],
    [1, 0.9751, 0.2786],
    [1, 0.97992, 0.32883],
    [1, 0.98432, 0.38571],
    [1, 0.9882, 0.44866],
    [1, 0.9915, 0.51653],
    [1, 0.99417, 0.58754],
    [1, 0.99625, 0.65985],
    [1, 0.99778, 0.73194],
    [1, 0.99885, 0.80259],
    [1, 0.99953, 0.87115],
    [1, 0.99989, 0.93683],
    [1, 1, 1],
];

// aka CET-L9
export const linear_bgyw_20_98_c66 = [
    [0.019721, 0, 0.67312],
    [0.021792, 0.0093198, 0.67457],
    [0.023764, 0.020375, 0.67602],
    [0.025723, 0.031533, 0.67744],
    [0.027593, 0.042972, 0.67884],
    [0.029434, 0.053399, 0.68022],
    [0.0312, 0.062887, 0.68158],
    [0.032926, 0.071508, 0.68292],
    [0.034668, 0.079503, 0.68423],
    [0.036359, 0.087188, 0.68553],
    [0.037918, 0.09442, 0.68679],
    [0.039376, 0.1013, 0.68804],
    [0.040814, 0.10797, 0.68926],
    [0.042148, 0.11441, 0.69045],
    [0.043258, 0.12068, 0.69161],
    [0.044531, 0.12678, 0.69276],
    [0.045545, 0.13274, 0.69387],
    [0.046606, 0.13856, 0.69495],
    [0.047504, 0.14425, 0.69602],
    [0.048422, 0.14985, 0.69703],
    [0.049286, 0.15533, 0.69803],
    [0.049957, 0.16073, 0.69899],
    [0.050622, 0.16607, 0.69992],
    [0.051213, 0.17134, 0.70083],
    [0.051756, 0.17652, 0.70168],
    [0.052246, 0.18167, 0.70251],
    [0.052659, 0.18678, 0.70331],
    [0.053041, 0.19182, 0.70406],
    [0.053359, 0.19684, 0.70477],
    [0.0536, 0.20176, 0.70545],
    [0.05381, 0.20672, 0.70608],
    [0.053957, 0.2116, 0.70667],
    [0.054028, 0.21646, 0.70721],
    [0.054057, 0.22128, 0.70771],
    [0.054033, 0.22609, 0.70816],
    [0.053937, 0.23085, 0.70856],
    [0.053775, 0.23561, 0.70892],
    [0.05358, 0.24032, 0.7092],
    [0.053321, 0.24504, 0.70944],
    [0.052992, 0.24974, 0.70962],
    [0.052597, 0.25445, 0.70974],
    [0.052169, 0.25912, 0.70979],
    [0.051684, 0.26379, 0.70978],
    [0.051133, 0.26843, 0.70969],
    [0.050513, 0.27307, 0.70954],
    [0.049841, 0.27771, 0.70932],
    [0.049147, 0.28235, 0.709],
    [0.04825, 0.28695, 0.70859],
    [0.04738, 0.29159, 0.7081],
    [0.046417, 0.29621, 0.70751],
    [0.045403, 0.30083, 0.70682],
    [0.044335, 0.30547, 0.70603],
    [0.043076, 0.3101, 0.70511],
    [0.041991, 0.31472, 0.70408],
    [0.040637, 0.31937, 0.70291],
    [0.039245, 0.32401, 0.70159],
    [0.037801, 0.32869, 0.70013],
    [0.036303, 0.33336, 0.6985],
    [0.034665, 0.33804, 0.69669],
    [0.033, 0.34276, 0.69467],
    [0.031386, 0.34749, 0.69245],
    [0.02977, 0.35224, 0.68995],
    [0.028127, 0.35704, 0.68719],
    [0.026459, 0.36185, 0.68412],
    [0.02481, 0.36671, 0.6807],
    [0.023175, 0.37161, 0.67686],
    [0.022142, 0.37657, 0.67264],
    [0.022449, 0.38146, 0.66815],
    [0.024367, 0.38628, 0.66347],
    [0.027722, 0.39108, 0.6586],
    [0.032448, 0.39582, 0.65355],
    [0.03852, 0.40052, 0.64833],
    [0.045286, 0.40516, 0.64294],
    [0.052276, 0.40978, 0.63739],
    [0.059484, 0.41435, 0.63169],
    [0.066532, 0.41889, 0.62583],
    [0.073577, 0.42341, 0.61983],
    [0.080364, 0.42791, 0.61368],
    [0.08713, 0.43237, 0.60741],
    [0.093557, 0.43681, 0.60099],
    [0.099853, 0.44122, 0.59445],
    [0.10599, 0.44562, 0.58778],
    [0.11186, 0.45001, 0.58099],
    [0.11745, 0.45438, 0.57408],
    [0.12286, 0.45873, 0.56706],
    [0.12813, 0.46305, 0.55991],
    [0.13311, 0.46739, 0.55265],
    [0.13789, 0.47171, 0.54528],
    [0.1424, 0.476, 0.53781],
    [0.14672, 0.48029, 0.53021],
    [0.15086, 0.48457, 0.52251],
    [0.15477, 0.48885, 0.51471],
    [0.15849, 0.49311, 0.50679],
    [0.16197, 0.49739, 0.49878],
    [0.16519, 0.50165, 0.49067],
    [0.16827, 0.50588, 0.48243],
    [0.17113, 0.51014, 0.47409],
    [0.17379, 0.51439, 0.46564],
    [0.17622, 0.51863, 0.45709],
    [0.1785, 0.52286, 0.44843],
    [0.1805, 0.52709, 0.43965],
    [0.18232, 0.53132, 0.43076],
    [0.18395, 0.53555, 0.42174],
    [0.18539, 0.53977, 0.41262],
    [0.18656, 0.544, 0.40336],
    [0.18757, 0.54823, 0.39396],
    [0.18834, 0.55246, 0.38443],
    [0.18903, 0.55666, 0.37491],
    [0.18989, 0.56081, 0.3656],
    [0.19095, 0.5649, 0.35655],
    [0.19225, 0.56895, 0.34772],
    [0.19379, 0.57295, 0.33913],
    [0.19553, 0.57689, 0.33073],
    [0.19754, 0.58079, 0.32256],
    [0.19976, 0.58465, 0.31455],
    [0.20225, 0.58845, 0.30674],
    [0.20498, 0.59224, 0.2991],
    [0.20794, 0.59596, 0.29161],
    [0.21114, 0.59966, 0.28426],
    [0.21456, 0.60332, 0.27707],
    [0.21821, 0.60694, 0.27001],
    [0.22206, 0.61053, 0.26306],
    [0.22618, 0.61408, 0.25623],
    [0.23049, 0.6176, 0.24956],
    [0.23505, 0.62109, 0.24299],
    [0.23975, 0.62454, 0.23654],
    [0.24464, 0.62797, 0.23013],
    [0.24971, 0.63137, 0.22383],
    [0.25499, 0.63473, 0.21763],
    [0.26043, 0.63806, 0.21154],
    [0.26605, 0.64137, 0.2055],
    [0.27177, 0.64465, 0.19951],
    [0.27767, 0.6479, 0.19362],
    [0.28367, 0.65113, 0.18778],
    [0.28987, 0.65432, 0.18198],
    [0.29618, 0.6575, 0.17626],
    [0.30259, 0.66063, 0.1706],
    [0.30913, 0.66376, 0.16494],
    [0.31579, 0.66684, 0.15938],
    [0.32256, 0.66991, 0.15386],
    [0.32942, 0.67295, 0.14835],
    [0.33634, 0.67597, 0.14288],
    [0.34341, 0.67895, 0.13748],
    [0.35057, 0.68191, 0.13209],
    [0.35779, 0.68486, 0.12667],
    [0.36507, 0.68777, 0.12127],
    [0.37248, 0.69065, 0.11603],
    [0.37996, 0.69352, 0.11072],
    [0.38748, 0.69636, 0.10536],
    [0.3951, 0.69917, 0.1001],
    [0.40279, 0.70196, 0.094912],
    [0.41054, 0.70473, 0.089608],
    [0.41833, 0.70747, 0.084378],
    [0.42622, 0.71018, 0.079119],
    [0.43414, 0.71287, 0.073963],
    [0.44214, 0.71554, 0.068745],
    [0.4502, 0.71818, 0.063586],
    [0.45829, 0.72079, 0.05835],
    [0.46644, 0.72338, 0.053154],
    [0.47467, 0.72595, 0.048139],
    [0.48291, 0.72849, 0.043005],
    [0.49122, 0.731, 0.038082],
    [0.49958, 0.73349, 0.033323],
    [0.50799, 0.73595, 0.029083],
    [0.51642, 0.7384, 0.025269],
    [0.52493, 0.7408, 0.021961],
    [0.53346, 0.7432, 0.018981],
    [0.54204, 0.74556, 0.016456],
    [0.55067, 0.74789, 0.014369],
    [0.55933, 0.7502, 0.012644],
    [0.56805, 0.75248, 0.01132],
    [0.5768, 0.75475, 0.010293],
    [0.58554, 0.75698, 0.0097626],
    [0.59425, 0.7592, 0.0095221],
    [0.60291, 0.76143, 0.0093793],
    [0.61149, 0.76365, 0.0093837],
    [0.62001, 0.76588, 0.0095486],
    [0.62846, 0.7681, 0.0098317],
    [0.63684, 0.77033, 0.010273],
    [0.64515, 0.77256, 0.010973],
    [0.6534, 0.77478, 0.011934],
    [0.6616, 0.77702, 0.012885],
    [0.66973, 0.77925, 0.014059],
    [0.6778, 0.78149, 0.015391],
    [0.68581, 0.78373, 0.016941],
    [0.69375, 0.78598, 0.018714],
    [0.70164, 0.78822, 0.02067],
    [0.70948, 0.79047, 0.022856],
    [0.71724, 0.79273, 0.02532],
    [0.72495, 0.79499, 0.027999],
    [0.73261, 0.79726, 0.030918],
    [0.74019, 0.79954, 0.034117],
    [0.74772, 0.80182, 0.037833],
    [0.75519, 0.80411, 0.041619],
    [0.7626, 0.80641, 0.045386],
    [0.76994, 0.80872, 0.049415],
    [0.77723, 0.81104, 0.053367],
    [0.78444, 0.81337, 0.057479],
    [0.79159, 0.81571, 0.061666],
    [0.79868, 0.81806, 0.065966],
    [0.80569, 0.82043, 0.070321],
    [0.81263, 0.82282, 0.074765],
    [0.8195, 0.82521, 0.079243],
    [0.82631, 0.82763, 0.083887],
    [0.83303, 0.83006, 0.088519],
    [0.83968, 0.83251, 0.093204],
    [0.84624, 0.83498, 0.098097],
    [0.85271, 0.83747, 0.10302],
    [0.85909, 0.83999, 0.10799],
    [0.86538, 0.84253, 0.11317],
    [0.87158, 0.84509, 0.11836],
    [0.87768, 0.84768, 0.12362],
    [0.88367, 0.85031, 0.12904],
    [0.88954, 0.85296, 0.13462],
    [0.89531, 0.85565, 0.14024],
    [0.90094, 0.85837, 0.14604],
    [0.90644, 0.86113, 0.15193],
    [0.9118, 0.86394, 0.15801],
    [0.91702, 0.86679, 0.16424],
    [0.92207, 0.86968, 0.17059],
    [0.92694, 0.87264, 0.17716],
    [0.93162, 0.87565, 0.18392],
    [0.9361, 0.87872, 0.19089],
    [0.94037, 0.88186, 0.19807],
    [0.9444, 0.88507, 0.2055],
    [0.94813, 0.88837, 0.21323],
    [0.95158, 0.89176, 0.22126],
    [0.9547, 0.89524, 0.22957],
    [0.95743, 0.89884, 0.23832],
    [0.95979, 0.90256, 0.24754],
    [0.96199, 0.9063, 0.25771],
    [0.96425, 0.90998, 0.26918],
    [0.96658, 0.91361, 0.28181],
    [0.96896, 0.91718, 0.29575],
    [0.9714, 0.92068, 0.31084],
    [0.97388, 0.92413, 0.32705],
    [0.9764, 0.92751, 0.34453],
    [0.97893, 0.93084, 0.36314],
    [0.98148, 0.93409, 0.38291],
    [0.98401, 0.93727, 0.40392],
    [0.98651, 0.94038, 0.42621],
    [0.98895, 0.94342, 0.4498],
    [0.9913, 0.94637, 0.47473],
    [0.99352, 0.94926, 0.50109],
    [0.99555, 0.95206, 0.52897],
    [0.99736, 0.95478, 0.55845],
    [0.99887, 0.95742, 0.58967],
    [1, 0.95997, 0.62277],
    [1, 0.96243, 0.65786],
    [1, 0.96481, 0.6951],
    [1, 0.96711, 0.7347],
    [0.99841, 0.96931, 0.77694],
    [0.99564, 0.97144, 0.82203],
    [0.99146, 0.97349, 0.87012],
    [0.98544, 0.97545, 0.92187],
    [0.97718, 0.97737, 0.97732],
];

export const bgy = [
    [0.0, 0.047803, 0.4883],
    [0.0, 0.049756, 0.49556],
    [0.0, 0.051605, 0.50281],
    [0.0, 0.053545, 0.51004],
    [0.0, 0.055585, 0.51721],
    [0.0, 0.057566, 0.52435],
    [0.0, 0.05978, 0.53144],
    [0.0, 0.061812, 0.53849],
    [0.0, 0.064016, 0.5455],
    [0.0, 0.066232, 0.55245],
    [0.0, 0.068551, 0.55934],
    [0.0, 0.070824, 0.5662],
    [0.0, 0.073229, 0.57299],
    [0.0, 0.07557, 0.57971],
    [0.0, 0.078003, 0.58638],
    [0.0, 0.080554, 0.59299],
    [0.0, 0.083114, 0.59951],
    [0.0, 0.085697, 0.60596],
    [0.0, 0.08842, 0.61236],
    [0.0, 0.091168, 0.61866],
    [0.0, 0.093925, 0.62487],
    [0.0, 0.096707, 0.63101],
    [0.0, 0.09963, 0.63705],
    [0.0, 0.1026, 0.64298],
    [0.0, 0.10561, 0.64881],
    [0.0, 0.10866, 0.65454],
    [0.0, 0.11183, 0.66016],
    [0.0, 0.11497, 0.66567],
    [0.0, 0.11829, 0.67103],
    [0.0, 0.12156, 0.67626],
    [0.0, 0.12498, 0.68134],
    [0.0, 0.12846, 0.68629],
    [0.0, 0.13201, 0.69107],
    [0.0, 0.13559, 0.6957],
    [0.0, 0.13927, 0.70014],
    [0.0, 0.14307, 0.70439],
    [0.0, 0.1469, 0.70845],
    [0.0, 0.15085, 0.71227],
    [0.0, 0.15487, 0.71588],
    [0.0, 0.159, 0.71923],
    [0.0, 0.16323, 0.7223],
    [0.0, 0.16754, 0.7251],
    [0.0, 0.17195, 0.72757],
    [0.0, 0.17647, 0.72972],
    [0.0, 0.18113, 0.73149],
    [0.0, 0.18594, 0.73289],
    [0.0, 0.19074, 0.73398],
    [0.0, 0.19556, 0.73486],
    [0.0, 0.20033, 0.73556],
    [0.0, 0.20512, 0.73608],
    [0.0, 0.20987, 0.73643],
    [0.0, 0.21461, 0.73659],
    [0.0, 0.21934, 0.73657],
    [0.0, 0.22402, 0.73637],
    [0.0, 0.22875, 0.73599],
    [0.0, 0.2334, 0.73544],
    [0.0, 0.23809, 0.73469],
    [0.0, 0.24275, 0.73376],
    [0.0, 0.24743, 0.73266],
    [0.0, 0.25208, 0.73137],
    [0.0, 0.25673, 0.72991],
    [0.0, 0.26137, 0.72825],
    [0.0, 0.26603, 0.72642],
    [0.0, 0.27068, 0.72441],
    [0.0, 0.27531, 0.72221],
    [0.0, 0.27995, 0.71983],
    [0.0, 0.28458, 0.71727],
    [0.0, 0.28924, 0.71452],
    [0.0, 0.29387, 0.71161],
    [0.0, 0.29852, 0.70851],
    [0.0, 0.30317, 0.70521],
    [0.0, 0.30782, 0.70174],
    [0.0, 0.31248, 0.69809],
    [0.0, 0.31716, 0.69426],
    [0.0, 0.32182, 0.69025],
    [0.0, 0.32649, 0.68607],
    [0.0, 0.33116, 0.68178],
    [0.0, 0.33582, 0.67746],
    [0.0, 0.34046, 0.6731],
    [0.0, 0.34509, 0.66871],
    [0.0, 0.3497, 0.66429],
    [0.0, 0.3543, 0.65984],
    [0.0, 0.35888, 0.65536],
    [0.0, 0.36346, 0.65085],
    [0.0, 0.36803, 0.6463],
    [0.0, 0.37258, 0.64173],
    [0.0, 0.37713, 0.63713],
    [0.0, 0.38167, 0.6325],
    [0.0, 0.38618, 0.62783],
    [0.0, 0.39071, 0.62313],
    [0.0, 0.39523, 0.6184],
    [0.0, 0.39972, 0.61363],
    [0.0, 0.40423, 0.60885],
    [0.0, 0.40872, 0.60402],
    [0.0, 0.41321, 0.59915],
    [0.0, 0.41769, 0.59426],
    [0.0, 0.42215, 0.58932],
    [0.0, 0.42663, 0.58437],
    [0.0, 0.4311, 0.57937],
    [0.0, 0.43556, 0.57433],
    [0.0, 0.44001, 0.56926],
    [0.0, 0.44446, 0.56416],
    [0.0, 0.44891, 0.55902],
    [0.0, 0.45334, 0.55384],
    [0.0, 0.45778, 0.54863],
    [0.0, 0.46222, 0.54336],
    [0.0, 0.46665, 0.53802],
    [0.0, 0.47105, 0.53253],
    [0.0, 0.47545, 0.52691],
    [0.0, 0.47982, 0.52115],
    [0.0, 0.48417, 0.51525],
    [0.0, 0.48852, 0.50921],
    [0.0, 0.49284, 0.50301],
    [0.0, 0.49717, 0.49668],
    [0.0, 0.50147, 0.49022],
    [0.0, 0.50575, 0.48359],
    [0.0, 0.51003, 0.47682],
    [0.0, 0.51431, 0.4699],
    [0.0, 0.51856, 0.4628],
    [0.0097866, 0.52281, 0.45558],
    [0.023896, 0.52704, 0.44818],
    [0.038383, 0.53126, 0.44061],
    [0.051763, 0.53547, 0.43289],
    [0.063442, 0.53968, 0.42499],
    [0.073828, 0.54388, 0.41692],
    [0.083244, 0.54807, 0.40866],
    [0.092062, 0.55225, 0.40022],
    [0.10019, 0.55642, 0.39159],
    [0.10786, 0.56059, 0.38276],
    [0.11513, 0.56474, 0.37372],
    [0.12206, 0.56889, 0.36445],
    [0.12871, 0.57304, 0.35498],
    [0.13507, 0.57718, 0.34524],
    [0.14115, 0.58131, 0.33527],
    [0.14697, 0.58544, 0.32499],
    [0.15257, 0.58954, 0.31449],
    [0.15773, 0.59367, 0.30393],
    [0.16231, 0.59779, 0.29352],
    [0.16631, 0.60191, 0.28332],
    [0.16984, 0.60603, 0.27332],
    [0.17292, 0.61015, 0.26352],
    [0.17565, 0.61427, 0.25387],
    [0.17811, 0.6184, 0.24439],
    [0.18021, 0.62252, 0.23514],
    [0.18207, 0.62664, 0.22606],
    [0.18374, 0.63076, 0.21715],
    [0.18522, 0.63487, 0.2084],
    [0.18649, 0.63898, 0.19982],
    [0.18765, 0.64309, 0.19148],
    [0.18863, 0.6472, 0.18334],
    [0.18951, 0.6513, 0.1754],
    [0.19029, 0.65539, 0.16767],
    [0.19099, 0.65948, 0.16013],
    [0.19162, 0.66357, 0.15293],
    [0.19219, 0.66765, 0.14604],
    [0.19272, 0.67172, 0.13937],
    [0.1932, 0.67579, 0.13311],
    [0.19366, 0.67985, 0.1272],
    [0.1941, 0.68391, 0.1218],
    [0.19451, 0.68797, 0.11692],
    [0.1949, 0.69202, 0.11259],
    [0.19529, 0.69606, 0.10881],
    [0.19569, 0.70009, 0.10581],
    [0.19611, 0.70412, 0.10358],
    [0.19653, 0.70814, 0.10209],
    [0.19694, 0.71215, 0.10139],
    [0.19736, 0.71617, 0.10116],
    [0.19779, 0.72018, 0.10101],
    [0.19823, 0.7242, 0.10087],
    [0.19868, 0.72822, 0.10073],
    [0.19914, 0.73225, 0.1006],
    [0.19961, 0.73627, 0.10048],
    [0.20009, 0.74031, 0.10036],
    [0.20058, 0.74434, 0.10025],
    [0.20108, 0.74838, 0.10015],
    [0.20159, 0.75242, 0.10006],
    [0.20211, 0.75647, 0.099977],
    [0.20265, 0.76052, 0.099902],
    [0.2032, 0.76457, 0.099835],
    [0.20376, 0.76862, 0.099777],
    [0.20433, 0.77267, 0.099729],
    [0.20488, 0.77674, 0.099691],
    [0.20546, 0.7808, 0.099663],
    [0.20608, 0.78487, 0.099645],
    [0.20669, 0.78894, 0.099637],
    [0.20729, 0.79301, 0.099641],
    [0.20791, 0.79708, 0.099656],
    [0.20855, 0.80116, 0.099683],
    [0.2092, 0.80523, 0.09972],
    [0.20987, 0.80932, 0.09977],
    [0.21055, 0.8134, 0.099833],
    [0.21125, 0.81749, 0.099908],
    [0.21196, 0.82159, 0.099996],
    [0.21266, 0.82567, 0.1001],
    [0.2134, 0.82977, 0.10021],
    [0.21454, 0.83386, 0.10034],
    [0.21746, 0.83784, 0.10049],
    [0.22334, 0.84166, 0.10065],
    [0.23182, 0.84531, 0.10083],
    [0.24228, 0.84884, 0.10102],
    [0.25428, 0.85224, 0.10123],
    [0.26735, 0.85553, 0.10145],
    [0.28125, 0.85872, 0.10168],
    [0.29571, 0.86184, 0.10193],
    [0.31067, 0.86485, 0.10219],
    [0.32594, 0.86779, 0.10246],
    [0.34137, 0.87065, 0.10275],
    [0.35684, 0.87346, 0.10306],
    [0.3724, 0.87619, 0.10337],
    [0.38805, 0.87886, 0.10369],
    [0.40366, 0.88147, 0.10401],
    [0.41921, 0.88402, 0.10433],
    [0.43478, 0.88651, 0.10468],
    [0.45028, 0.88894, 0.10507],
    [0.4657, 0.89133, 0.10549],
    [0.48111, 0.89365, 0.10592],
    [0.49641, 0.89593, 0.10637],
    [0.51169, 0.89815, 0.10681],
    [0.52691, 0.90032, 0.10725],
    [0.54202, 0.90245, 0.1077],
    [0.55713, 0.90452, 0.10817],
    [0.57212, 0.90655, 0.10869],
    [0.5871, 0.90852, 0.10927],
    [0.60201, 0.91045, 0.10983],
    [0.61689, 0.91232, 0.11037],
    [0.63169, 0.91415, 0.11095],
    [0.64646, 0.91593, 0.11155],
    [0.66118, 0.91767, 0.11216],
    [0.67585, 0.91934, 0.11281],
    [0.69049, 0.92098, 0.11347],
    [0.70508, 0.92257, 0.11409],
    [0.71966, 0.92411, 0.11477],
    [0.73418, 0.9256, 0.11552],
    [0.74868, 0.92704, 0.11627],
    [0.76314, 0.92844, 0.11699],
    [0.77759, 0.92979, 0.11777],
    [0.79201, 0.93109, 0.11859],
    [0.8064, 0.93233, 0.11937],
    [0.82077, 0.93353, 0.12019],
    [0.83511, 0.93468, 0.12102],
    [0.84946, 0.93578, 0.12189],
    [0.86375, 0.93684, 0.12278],
    [0.87808, 0.93783, 0.1237],
    [0.89234, 0.93878, 0.12464],
    [0.90664, 0.93968, 0.12562],
    [0.92088, 0.94052, 0.12657],
    [0.93514, 0.94131, 0.12755],
    [0.94939, 0.94206, 0.12857],
    [0.9636, 0.94275, 0.12961],
    [0.97785, 0.94338, 0.13068],
    [0.99205, 0.94397, 0.13172],
    [1.0, 0.94449, 0.13281],
    [1.0, 0.94497, 0.13392],
    [1.0, 0.94539, 0.13505],
    [1.0, 0.94574, 0.13614],
    [1.0, 0.94606, 0.13735],
]
